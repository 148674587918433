import React from 'react';
import getSvg from "../../utils/getSvg";

export default function PackageFilter({numberOfPackages, pFirst, pSecond, sortByText, filterValue, updateFeed}) {
    const [showModal, setShowModal] = React.useState(false);
    return (
        <>
            <div className="fz-fd-row fz-jc-space-between">
                <div className="homefeed--details">
                    <div className="homefeed--details__quantity fz-block-sm">
                        <p>{pFirst} <span className="count">{numberOfPackages}</span> {pSecond}</p>
                    </div>
                </div>
                <a className="fz-info" onClick={() => setShowModal(true)}>
                                <span className="icon">
                                    <svg width="24" height="24" viewBox="0 0 24 24" fill="none"
                                         xmlns="http://www.w3.org/2000/svg">
                                        <path
                                            d="M12 16V12M12 8H12.01M22 12C22 17.5228 17.5228 22 12 22C6.47715 22 2 17.5228 2 12C2 6.47715 6.47715 2 12 2C17.5228 2 22 6.47715 22 12Z"
                                            stroke="currentColor" stroke-width="2" stroke-linecap="round"
                                            stroke-linejoin="round"/>
                                        </svg>
                                </span>
                    <span className="text">Info</span>
                </a>
            </div>
            {showModal &&
                <div className="modal-container">
                    <div className="modal modal-lg">
                        <div className="modal__header">
                            <p></p>
                            <button type="button" className="button-tertiary button-s button-icon modal-close"
                                    onClick={() => {
                                        setShowModal(false)
                                    }}>
                                {getSvg('close')}
                            </button>
                        </div>
                        <div class="modal__content">
                            <div class="fz-jc-center fz-block">
                                <svg width="198" height="192" viewBox="0 0 198 192" fill="none"
                                     xmlns="http://www.w3.org/2000/svg">
                                    <path
                                        d="M116.487 130.355H3.19984C1.43345 130.353 0.00200185 128.921 0 127.155V95.1632C0.00200185 93.3968 1.43345 91.9654 3.19984 91.9634H116.487C118.254 91.9654 119.685 93.3968 119.687 95.1632V127.155C119.685 128.921 118.254 130.353 116.487 130.355ZM3.19984 92.9796C1.99442 92.981 1.01761 93.9579 1.01617 95.1632V127.155C1.01761 128.36 1.99442 129.337 3.19984 129.339H116.487C117.693 129.337 118.67 128.36 118.671 127.155V95.1632C118.67 93.9578 117.693 92.981 116.487 92.9795L3.19984 92.9796Z"
                                        fill="#CCCCCC"/>
                                    <path
                                        d="M156.477 185.857L151.872 185.856L149.681 168.093L156.478 168.093L156.477 185.857Z"
                                        fill="#D48460"/>
                                    <path
                                        d="M156.639 190.661L142.477 190.661V190.482C142.478 187.437 144.945 184.97 147.99 184.969L150.577 183.007L155.403 184.97L156.64 184.97L156.639 190.661Z"
                                        fill="#2F2E41"/>
                                    <path
                                        d="M191.567 161.617L189.387 165.673L172.703 159.191L175.922 153.205L191.567 161.617Z"
                                        fill="#D48460"/>
                                    <path
                                        d="M195.876 163.749L189.169 176.222L189.011 176.138C186.33 174.696 185.325 171.354 186.767 168.672L186.263 165.464L190.277 162.143L190.863 161.054L195.876 163.749Z"
                                        fill="#2F2E41"/>
                                    <path
                                        d="M147.77 104.508L143.706 137.026L145.738 147.928L182.32 163.954L184.352 157.349C175.001 151.627 165.005 142.968 158.707 142.331C158.707 142.331 172.412 110.859 171.904 108.319C171.396 105.779 147.77 104.508 147.77 104.508Z"
                                        fill="#2F2E41"/>
                                    <path
                                        d="M147.07 136.932L144.944 150.664L150.311 178.689H157.424C159.979 169.272 160.111 156.896 157.678 146.425L147.07 136.932Z"
                                        fill="#2F2E41"/>
                                    <path
                                        d="M151.64 64.7786L154.919 59.0647L158.44 56.2403L165.045 55.2241L166.88 56.6352C173.484 66.7286 175.947 78.9779 173.757 90.8394L171.954 100.606L173.505 107.975C173.993 110.292 172.511 112.566 170.193 113.054C169.909 113.114 169.619 113.144 169.328 113.146L152.939 113.213C148.85 113.23 145.522 109.929 145.505 105.839C145.503 105.458 145.531 105.077 145.588 104.699L151.64 64.7786Z"
                                        fill="#E6E6E6"/>
                                    <path
                                        d="M164.504 84.4543L157.955 83.5161C155.74 87.2202 154.952 97.0792 154.952 97.0792L148.542 112.314C147.208 113.221 146.861 115.038 147.767 116.372C148.674 117.707 150.49 118.054 151.825 117.148C152.691 116.559 153.176 115.552 153.096 114.509L163.621 96.7753L164.504 84.4543Z"
                                        fill="#D48460"/>
                                    <path
                                        d="M163.462 66.2709C167.272 66.8609 169.882 70.4279 169.292 74.238C169.226 74.6649 169.12 75.0848 168.976 75.4922L167.587 79.4318C167.587 79.4318 166.83 91.2939 163.945 94.6423L155.09 93.8408C155.09 93.8408 156.709 87.8562 155.466 84.9606C154.705 83.1873 154.748 77.0626 155.498 72.0802C156.088 68.2802 159.647 65.6782 163.447 66.2686L163.462 66.2709Z"
                                        fill="#E6E6E6"/>
                                    <path
                                        d="M150.779 73.0504L149.223 66.6192C144.962 65.9341 135.513 68.8559 135.513 68.8559L118.987 68.5471C117.651 67.6433 115.835 67.9941 114.931 69.3305C114.027 70.667 114.378 72.483 115.715 73.3868C116.582 73.9732 117.697 74.0507 118.637 73.5899L139.007 76.7948L150.779 73.0504Z"
                                        fill="#D48460"/>
                                    <path
                                        d="M167.282 65.3449C168.146 69.1024 165.8 72.8485 162.042 73.7122C161.621 73.8089 161.192 73.8664 160.76 73.8839L156.586 74.0526C156.586 74.0526 145.288 77.7449 141.109 76.3055L138.573 67.7846C138.573 67.7846 144.731 67.0708 146.96 64.8436C148.325 63.4796 154.03 61.2496 158.935 60.1001C162.684 59.2404 166.419 61.5822 167.279 65.3304L167.282 65.3449Z"
                                        fill="#E6E6E6"/>
                                    <path
                                        d="M168.585 46.3389C169.353 41.6042 166.138 37.1432 161.403 36.3748C156.668 35.6065 152.207 38.8219 151.439 43.5565C150.671 48.2912 153.886 52.7522 158.621 53.5206C163.355 54.2889 167.816 51.0736 168.585 46.3389Z"
                                        fill="#D48460"/>
                                    <path
                                        d="M160.159 53.3856C161.43 53.4055 162.7 53.3167 163.956 53.1203C165.2 52.8672 167.985 50.8268 168.761 49.599C169.326 48.5457 169.716 47.4069 169.914 46.2279C170.48 43.4598 169.846 40.5815 168.169 38.3076C167.85 37.8373 167.411 37.4619 166.896 37.221C166.85 37.2032 166.804 37.1874 166.756 37.1739C166.006 36.9766 165.333 36.5597 164.822 35.9768C164.722 35.8589 164.615 35.7483 164.5 35.6458C163.922 35.1583 163.237 34.8144 162.501 34.6423C161.313 34.3243 159.594 34.6399 157.391 35.5813C156.344 35.9489 155.21 35.9838 154.143 35.6811C154.036 35.6606 153.927 35.698 153.856 35.7794C153.46 36.1513 152.938 36.3595 152.394 36.3621C152.061 36.3767 151.712 36.8375 151.29 37.4384C151.194 37.5748 151.083 37.734 151.002 37.8308L150.991 37.6448L150.803 37.8523C149.834 38.9199 149.915 40.5704 150.982 41.5389C151.269 41.7992 151.61 41.9923 151.981 42.1043C152.316 42.1934 152.659 42.2481 153.005 42.2675C153.214 42.2863 153.431 42.3058 153.639 42.3419C154.995 42.6195 156.105 43.5891 156.563 44.8952C156.755 45.4302 157.344 45.7084 157.879 45.5166C157.963 45.4864 158.042 45.4455 158.116 45.3948C158.555 45.0529 159.134 44.9475 159.665 45.1127C159.891 45.2386 160.067 45.4383 160.164 45.6786C160.281 45.9463 160.475 46.173 160.722 46.3298C161.565 46.7646 161.624 48.691 161.321 50.2969C161.029 51.8449 160.452 52.9647 159.917 53.0201C159.506 53.0626 159.459 53.0919 159.429 53.1646L159.402 53.2297L159.448 53.2907C159.676 53.3731 159.918 53.4055 160.159 53.3856Z"
                                        fill="#2F2E41"/>
                                    <path
                                        d="M116.487 82.5256H3.19986C1.71392 82.5239 0.509743 81.3197 0.508057 79.8338V47.8421C0.509764 46.3562 1.71395 45.152 3.19986 45.1504H116.487C117.973 45.1521 119.177 46.3562 119.179 47.8421V79.8338C119.177 81.3197 117.973 82.5239 116.487 82.5256Z"
                                        fill="white"/>
                                    <path
                                        d="M116.487 37.8835H3.19986C1.71392 37.8818 0.509743 36.6776 0.508057 35.1917V3.2C0.509764 1.71409 1.71395 0.509947 3.19986 0.508301H116.487C117.973 0.509962 119.177 1.71409 119.179 3.2V35.1917C119.177 36.6776 117.973 37.8818 116.487 37.8835Z"
                                        fill="white"/>
                                    <path
                                        d="M116.487 83.0335H3.19984C1.43345 83.0315 0.00200185 81.6 0 79.8336V47.8419C0.00200185 46.0755 1.43345 44.6441 3.19984 44.6421H116.487C118.254 44.6441 119.685 46.0755 119.687 47.8419V79.8336C119.685 81.6 118.254 83.0315 116.487 83.0335ZM3.19984 45.6583C1.99442 45.6597 1.01761 46.6365 1.01617 47.842V79.8336C1.01761 81.0391 1.99442 82.0159 3.19984 82.0173H116.487C117.693 82.0159 118.67 81.0391 118.671 79.8336V47.8419C118.67 46.6365 117.693 45.6597 116.487 45.6583L3.19984 45.6583Z"
                                        fill="#CCCCCC"/>
                                    <path
                                        d="M116.487 38.3914H3.19984C1.43345 38.3894 0.00200185 36.9579 0 35.1915V3.19983C0.00200185 1.43345 1.43345 0.00200185 3.19984 0H116.487C118.254 0.00200185 119.685 1.43345 119.687 3.19983V35.1915C119.685 36.9579 118.254 38.3894 116.487 38.3914ZM3.19984 1.01619C1.99442 1.01763 1.01761 1.99445 1.01617 3.19986V35.1915C1.01761 36.397 1.99442 37.3738 3.19984 37.3752H116.487C117.693 37.3738 118.67 36.397 118.671 35.1915V3.19983C118.67 1.99442 117.693 1.01761 116.487 1.01617L3.19984 1.01619Z"
                                        fill="#CCCCCC"/>
                                    <path
                                        d="M109.23 25.3597H10.457C9.73866 25.3607 9.15552 24.7791 9.15454 24.0608C9.15355 23.3424 9.7351 22.7593 10.4535 22.7583H109.23C109.948 22.7573 110.531 23.3389 110.532 24.0572C110.533 24.7756 109.952 25.3587 109.233 25.3597H109.23Z"
                                        fill="#3D3D3D"/>
                                    <path
                                        d="M109.23 71.0872H10.457C9.73866 71.0882 9.15552 70.5067 9.15454 69.7883C9.15355 69.07 9.7351 68.4868 10.4535 68.4858H109.23C109.948 68.4849 110.531 69.0664 110.532 69.7848C110.533 70.5031 109.952 71.0862 109.233 71.0872H109.23Z"
                                        fill="#3D3D3D"/>
                                    <path
                                        d="M98.6076 30.4995H10.457C9.73862 30.4995 9.15625 29.9171 9.15625 29.1987C9.15625 28.4803 9.73862 27.8979 10.457 27.8979H98.6076C99.326 27.8979 99.9084 28.4803 99.9084 29.1987C99.9084 29.9171 99.326 30.4995 98.6076 30.4995Z"
                                        fill="#3D3D3D"/>
                                    <path
                                        d="M98.6076 76.227H10.457C9.73862 76.227 9.15625 75.6447 9.15625 74.9263C9.15625 74.2079 9.73862 73.6255 10.457 73.6255H98.6076C99.326 73.6255 99.9084 74.2079 99.9084 74.9263C99.9084 75.6447 99.326 76.227 98.6076 76.227Z"
                                        fill="#3D3D3D"/>
                                    <path
                                        d="M116.487 129.846H3.19986C1.71392 129.845 0.509759 128.64 0.508057 127.155V95.1629C0.509764 93.677 1.71395 92.4728 3.19986 92.4712H116.487C117.973 92.4728 119.177 93.677 119.179 95.1629V127.155C119.177 128.64 117.973 129.845 116.487 129.846Z"
                                        fill="white"/>
                                    <path
                                        d="M198 191.5C198.001 191.775 197.792 191.999 197.533 192H20.47C20.2104 192 20 191.776 20 191.5C20 191.224 20.2104 191 20.47 191H197.53C197.789 190.999 197.999 191.222 198 191.497V191.5Z"
                                        fill="#CCCCCC"/>
                                    <rect x="8.04761" y="5.58887" width="106.19" height="14.7344" rx="7.36722"
                                          fill="#D9D9D9"/>
                                    <rect x="8.04761" y="51.3164" width="106.19" height="14.7344" rx="7.36722"
                                          fill="#D9D9D9"/>
                                    <rect x="8.04761" y="98.5684" width="106.19" height="14.7344" rx="7.36722"
                                          fill="#D9D9D9"/>
                                    <path
                                        d="M109.23 118.339H10.457C9.73866 118.34 9.15552 117.758 9.15454 117.04C9.15355 116.321 9.7351 115.738 10.4535 115.737H109.23C109.948 115.736 110.531 116.318 110.532 117.036C110.533 117.755 109.952 118.338 109.233 118.339H109.23Z"
                                        fill="#3D3D3D"/>
                                    <path
                                        d="M98.6076 123.478H10.457C9.73862 123.478 9.15625 122.896 9.15625 122.178C9.15625 121.459 9.73862 120.877 10.457 120.877H98.6076C99.326 120.877 99.9084 121.459 99.9084 122.178C99.9084 122.896 99.326 123.478 98.6076 123.478Z"
                                        fill="#3D3D3D"/>
                                </svg>

                                {/*<svg width="214" height="187" viewBox="0 0 214 187" fill="none"*/}
                                {/*     xmlns="http://www.w3.org/2000/svg">*/}
                                {/*    <g clip-path="url(#clip0_8959_13895)">*/}
                                {/*        <path*/}
                                {/*            d="M126.794 185.303L123.107 183.751L120.577 165.271H86.8878L84.1457 183.675L80.8464 185.324C80.6885 185.403 80.562 185.533 80.4872 185.693C80.4124 185.852 80.3937 186.033 80.4343 186.204C80.4748 186.376 80.5722 186.529 80.7106 186.639C80.849 186.748 81.0203 186.808 81.1968 186.808H126.49C126.671 186.808 126.847 186.745 126.987 186.631C127.127 186.516 127.223 186.357 127.258 186.18C127.294 186.002 127.268 185.818 127.183 185.658C127.098 185.499 126.961 185.373 126.794 185.303Z"*/}
                                {/*            fill="#E6E6E6"/>*/}
                                {/*        <path*/}
                                {/*            d="M186.662 167.73H21.1724C20.3041 167.728 19.4721 167.381 18.8588 166.767C18.2456 166.153 17.9011 165.32 17.9011 164.452V141.126H189.933V164.452C189.933 165.32 189.589 166.153 188.975 166.767C188.362 167.381 187.53 167.728 186.662 167.73Z"*/}
                                {/*            fill="#CCCCCC"/>*/}
                                {/*        <path*/}
                                {/*            d="M190.106 152.151H17.75V52.3423C17.7512 51.2948 18.168 50.2906 18.909 49.55C19.65 48.8094 20.6546 48.3928 21.7025 48.3916H186.153C187.201 48.3928 188.206 48.8094 188.947 49.55C189.688 50.2906 190.105 51.2948 190.106 52.3423V152.151Z"*/}
                                {/*            fill="#3F3D56"/>*/}
                                {/*        <path*/}
                                {/*            d="M179.812 144.906H28.0441C27.2363 144.906 26.4618 144.584 25.8905 144.013C25.3193 143.442 24.998 142.668 24.9971 141.861V58.6811C24.998 57.8736 25.3193 57.0994 25.8905 56.5284C26.4618 55.9574 27.2363 55.6362 28.0441 55.6353H179.812C180.62 55.6362 181.394 55.9574 181.965 56.5284C182.537 57.0994 182.858 57.8736 182.859 58.6811V141.861C182.858 142.668 182.537 143.442 181.965 144.013C181.394 144.584 180.62 144.906 179.812 144.906Z"*/}
                                {/*            fill="white"/>*/}
                                {/*        <path*/}
                                {/*            d="M146.492 187H61.2113C61.1239 187.002 61.0384 186.975 60.9681 186.923C60.8977 186.871 60.8465 186.797 60.8223 186.714C60.8073 186.656 60.8055 186.597 60.8172 186.539C60.8289 186.481 60.8537 186.426 60.8898 186.379C60.9259 186.333 60.9722 186.295 61.0252 186.269C61.0782 186.242 61.1366 186.229 61.1957 186.229H146.46C146.552 186.226 146.643 186.254 146.717 186.308C146.791 186.363 146.845 186.441 146.869 186.53C146.881 186.587 146.881 186.645 146.868 186.701C146.854 186.758 146.829 186.81 146.793 186.855C146.757 186.9 146.711 186.937 146.659 186.962C146.607 186.987 146.549 187 146.492 187Z"*/}
                                {/*            fill="#CCCCCC"/>*/}
                                {/*        <path*/}
                                {/*            d="M162.609 130.099H130.633C130.554 130.106 130.475 130.081 130.413 130.03C130.351 129.979 130.312 129.906 130.304 129.827V104.045C130.312 103.965 130.351 103.892 130.413 103.842C130.475 103.791 130.554 103.766 130.633 103.773H162.609C162.689 103.766 162.768 103.791 162.829 103.842C162.891 103.892 162.93 103.965 162.938 104.045V129.827C162.93 129.906 162.891 129.979 162.829 130.03C162.768 130.081 162.689 130.106 162.609 130.099Z"*/}
                                {/*            fill="#F0F0F0"/>*/}
                                {/*        <path*/}
                                {/*            d="M159.134 106.625H134.109C134.022 106.625 133.938 106.659 133.877 106.721C133.815 106.783 133.78 106.866 133.78 106.954V126.336C133.78 126.423 133.815 126.507 133.877 126.568C133.938 126.63 134.022 126.665 134.109 126.665H159.134C159.221 126.665 159.305 126.63 159.367 126.568C159.428 126.507 159.463 126.423 159.463 126.336V106.954C159.463 106.866 159.428 106.783 159.367 106.721C159.305 106.659 159.221 106.625 159.134 106.625Z"*/}
                                {/*            fill="white"/>*/}
                                {/*        <path*/}
                                {/*            d="M146.003 119.758C147.76 119.758 149.185 118.334 149.185 116.578C149.185 114.821 147.76 113.397 146.003 113.397C144.246 113.397 142.821 114.821 142.821 116.578C142.821 118.334 144.246 119.758 146.003 119.758Z"*/}
                                {/*            fill="#FFB8B8"/>*/}
                                {/*        <path*/}
                                {/*            d="M150.658 126.665H143.604L143.834 121.481L143.889 121.424L144.681 120.587H147.2L148.134 121.492L148.209 121.565L150.197 123.469L150.658 126.665Z"*/}
                                {/*            fill="#D0DDFF"/>*/}
                                {/*        <path*/}
                                {/*            d="M144.817 126.665H139.48C139.467 125.495 139.46 124.794 139.46 124.794L139.768 124.606L139.77 124.606H139.771L143.172 122.542L143.886 121.382L143.89 121.423L144.1 123.867C144.1 123.867 144.446 124.987 144.817 126.665Z"*/}
                                {/*            fill="#2F2E41"/>*/}
                                {/*        <path*/}
                                {/*            d="M153.787 126.665H148.134C148.219 123.253 147.758 121.382 147.996 121.382L148.134 121.491L149.8 122.806L151.996 123.612H151.997L153.511 124.167L153.777 124.264V124.389C153.778 124.689 153.779 125.509 153.787 126.665Z"*/}
                                {/*            fill="#2F2E41"/>*/}
                                {/*        <path*/}
                                {/*            d="M142.993 117.573C142.993 117.573 143.263 117.099 143.2 115.513C143.137 113.928 145.553 114.825 147.571 114.673C149.589 114.522 149.161 117.32 149.161 117.32C149.161 117.32 149.307 117.154 149.619 115.803C149.93 114.453 149.223 113.107 149.223 113.107C149.014 111.688 147.891 112.143 147.891 112.143C148.033 112.179 148.156 112.267 148.237 112.389C148.318 112.511 148.35 112.659 148.328 112.804C147.87 112.001 146.747 112.456 146.747 112.456C145.248 111.516 143.73 112.754 143.73 112.754C141.92 113.164 142.982 114.603 142.982 114.603C141.161 115.523 142.993 117.573 142.993 117.573Z"*/}
                                {/*            fill="#2F2E41"/>*/}
                                {/*        <path*/}
                                {/*            d="M140.193 126.666H138C138.017 126.396 138.017 126.125 138.001 125.854C137.87 125.333 139.713 124.628 139.768 124.607L139.77 124.606H139.77L140.122 124.795L140.193 126.666Z"*/}
                                {/*            fill="#2F2E41"/>*/}
                                {/*        <path*/}
                                {/*            d="M154.183 126.665H151.879L151.592 123.827L151.914 123.592C151.914 123.592 151.944 123.598 151.996 123.612H151.997C152.523 123.731 153.032 123.918 153.511 124.167C153.613 124.224 153.703 124.299 153.777 124.389C153.822 124.443 153.845 124.513 153.84 124.584C153.828 124.69 153.963 125.471 154.183 126.665Z"*/}
                                {/*            fill="#2F2E41"/>*/}
                                {/*        <path*/}
                                {/*            d="M162.505 97.2035H130.529C130.45 97.2102 130.37 97.1854 130.309 97.1344C130.247 97.0835 130.208 97.0105 130.2 96.931V71.1402C130.208 71.0608 130.247 70.9877 130.309 70.9368C130.37 70.8859 130.45 70.8611 130.529 70.8678H162.505C162.585 70.8611 162.664 70.8859 162.725 70.9368C162.787 70.9877 162.826 71.0608 162.834 71.1402V96.931C162.826 97.0105 162.787 97.0835 162.725 97.1345C162.664 97.1854 162.585 97.2102 162.505 97.2035Z"*/}
                                {/*            fill="#F0F0F0"/>*/}
                                {/*        <path*/}
                                {/*            d="M159.03 73.7202H134.005C133.918 73.7203 133.834 73.7551 133.772 73.8168C133.711 73.8785 133.676 73.9621 133.676 74.0493V93.4316C133.676 93.5189 133.711 93.6025 133.772 93.6642C133.834 93.7259 133.918 93.7606 134.005 93.7607H159.03C159.117 93.7606 159.201 93.7259 159.262 93.6642C159.324 93.6025 159.359 93.5189 159.359 93.4316V74.0493C159.359 73.9621 159.324 73.8785 159.262 73.8168C159.201 73.7551 159.117 73.7203 159.03 73.7202Z"*/}
                                {/*            fill="white"/>*/}
                                {/*        <path*/}
                                {/*            d="M152.677 93.7613C152.818 93.253 152.928 92.8148 152.984 92.5047C153.001 92.4091 153.003 92.3113 152.989 92.2151C152.778 90.7164 148.972 89.4496 148.484 89.2931L148.366 87.9883L143.575 87.4727L142.968 89.1417L141.249 89.7861C141.187 89.8091 141.127 89.8395 141.072 89.8768C140.928 89.9741 140.817 90.1133 140.755 90.2756C140.692 90.4379 140.681 90.6155 140.722 90.7845L141.454 93.7613L152.677 93.7613Z"*/}
                                {/*            fill="#D0DDFF"/>*/}
                                {/*        <path*/}
                                {/*            d="M142.745 93.761H139.027C139.16 92.9315 139.279 92.1979 139.357 91.7313C139.641 90.033 140.879 89.8882 141.073 89.8765C141.088 89.875 141.098 89.875 141.098 89.875H141.881L142.745 93.761Z"*/}
                                {/*            fill="#D0DDFF"/>*/}
                                {/*        <path*/}
                                {/*            d="M152.677 93.7615H153.579C153.535 93.2002 153.33 92.6635 152.99 92.2153C152.819 91.9896 152.598 91.8061 152.345 91.6792L152.277 91.6499L151.484 93.7615H152.677Z"*/}
                                {/*            fill="#D0DDFF"/>*/}
                                {/*        <path*/}
                                {/*            d="M145.784 86.6472C147.689 86.6472 149.234 85.1033 149.234 83.1988C149.234 81.2944 147.689 79.7505 145.784 79.7505C143.879 79.7505 142.334 81.2944 142.334 83.1988C142.334 85.1033 143.879 86.6472 145.784 86.6472Z"*/}
                                {/*            fill="#A0616A"/>*/}
                                {/*        <path*/}
                                {/*            d="M143.468 81.5183C143.825 81.9408 144.429 82.0599 144.987 82.1084C146.29 82.1804 147.597 82.0737 148.872 81.7914C148.951 82.5564 148.736 83.3813 149.186 84.0108C149.398 83.2488 149.52 82.4651 149.552 81.675C149.586 81.3361 149.542 80.9939 149.423 80.6747C149.359 80.5147 149.252 80.3757 149.114 80.2736C148.975 80.1714 148.811 80.1102 148.639 80.0969C148.896 79.902 149.197 79.7734 149.516 79.7224L148.42 79.1722L148.701 78.8835L146.718 78.7622L147.293 78.3975C146.428 78.2788 145.553 78.2513 144.682 78.3154C144.276 78.3156 143.877 78.4264 143.528 78.6361C143.201 78.8702 143.012 79.3353 143.215 79.6789C142.961 79.7207 142.721 79.8267 142.519 79.987C142.317 80.1472 142.159 80.3563 142.061 80.5946C141.936 80.9732 141.906 81.3766 141.973 81.7696C142.048 82.4 142.194 83.0199 142.41 83.617"*/}
                                {/*            fill="#2F2E41"/>*/}
                                {/*        <path*/}
                                {/*            d="M44.9177 129.378V71.0588C44.9382 70.8778 45.0287 70.712 45.1698 70.5969C45.311 70.4818 45.4917 70.4264 45.6731 70.4427H119.031C119.213 70.4264 119.394 70.4818 119.535 70.5969C119.676 70.712 119.766 70.8778 119.787 71.0588V129.378C119.766 129.559 119.676 129.725 119.535 129.84C119.394 129.955 119.213 130.01 119.031 129.994H45.6731C45.4917 130.01 45.311 129.955 45.1698 129.84C45.0287 129.725 44.9382 129.559 44.9177 129.378Z"*/}
                                {/*            fill="#E4E4E4"/>*/}
                                {/*        <path*/}
                                {/*            d="M52.8914 77.7424V122.209C52.8917 122.409 52.9713 122.601 53.1129 122.742C53.2545 122.884 53.4465 122.963 53.6468 122.964H111.058C111.258 122.963 111.45 122.884 111.591 122.742C111.733 122.601 111.813 122.409 111.813 122.209V77.7424C111.813 77.5422 111.733 77.3503 111.591 77.2088C111.45 77.0673 111.258 76.9876 111.058 76.9873H53.6468C53.4465 76.9876 53.2545 77.0673 53.1129 77.2088C52.9713 77.3503 52.8917 77.5422 52.8914 77.7424Z"*/}
                                {/*            fill="white"/>*/}
                                {/*        <path*/}
                                {/*            d="M76.7036 122.964H88.2579L87.5378 112.161L83.3696 113.122L79.3156 111.564L79.2082 111.523L79.2015 111.52C79.2 111.523 79.1989 111.527 79.1981 111.53C79.1343 111.787 77.8619 116.879 76.7036 122.964Z"*/}
                                {/*            fill="#D0DDFF"/>*/}
                                {/*        <path*/}
                                {/*            d="M69.7402 113.924L70.8196 122.964H80.5577L79.3156 111.564L79.3037 111.453L79.2082 111.523L79.1981 111.53L76.7957 113.282L69.7402 113.924Z"*/}
                                {/*            fill="#2F2E41"/>*/}
                                {/*        <path*/}
                                {/*            d="M86.5491 122.964H95.2698L95.3957 115.848L88.9781 113.72L87.5378 112.161C87.5378 112.161 87.0829 116.851 86.5491 122.964Z"*/}
                                {/*            fill="#2F2E41"/>*/}
                                {/*        <path*/}
                                {/*            d="M65.542 122.964H72.7688L72.3054 116.489L69.7404 113.925C69.7404 113.925 67.7109 118.18 65.542 122.964Z"*/}
                                {/*            fill="#2F2E41"/>*/}
                                {/*        <path*/}
                                {/*            d="M93.2131 122.963H97.0254C97.0086 120.889 96.9986 119.456 96.9986 119.052C97.0177 118.426 96.8812 117.806 96.6014 117.247C96.3216 116.688 95.9072 116.206 95.3954 115.847L93.47 116.488L93.2131 122.963Z"*/}
                                {/*            fill="#2F2E41"/>*/}
                                {/*        <path*/}
                                {/*            d="M93.8081 108.762H73.6072V100.251C73.6072 97.3346 75.6629 93.8279 78.1897 92.4343C81.7362 90.4784 85.6134 90.4441 88.827 92.3405C90.3397 93.2357 91.5939 94.5082 92.467 96.0333C93.3401 97.5585 93.8022 99.284 93.8081 101.041V108.762Z"*/}
                                {/*            fill="#2F2E41"/>*/}
                                {/*        <path*/}
                                {/*            d="M90.5096 104.756C92.4425 101.222 91.143 96.7905 87.6071 94.8584C84.0713 92.9263 79.638 94.2253 77.7051 97.7596C75.7722 101.294 77.0717 105.725 80.6076 107.657C84.1434 109.59 88.5767 108.291 90.5096 104.756Z"*/}
                                {/*            fill="#FFB8B8"/>*/}
                                {/*        <path*/}
                                {/*            d="M91.0431 101.635H80.6337L80.6091 101.517C80.4886 100.896 80.3329 100.284 80.1428 99.6811C79.7294 100.286 79.3482 100.913 79.0008 101.558L78.9584 101.635H76.9347C76.8105 101.635 76.6877 101.609 76.5742 101.559C76.4606 101.508 76.3588 101.435 76.2751 101.343C76.2201 101.283 76.0913 101.141 76.6243 94.6372C76.639 94.4823 76.6936 94.3339 76.7829 94.2065C76.8722 94.0791 76.9931 93.977 77.1337 93.9103C81.701 91.7791 86.3294 91.8355 90.8914 94.0788H90.9728L91.0014 94.1919C91.9013 101.114 91.7719 101.264 91.7166 101.327C91.633 101.424 91.5295 101.501 91.4133 101.554C91.2971 101.607 91.1708 101.635 91.0431 101.635Z"*/}
                                {/*            fill="#2F2E41"/>*/}
                                {/*        <path*/}
                                {/*            d="M48.5961 80.0432L37.4944 178.863C37.4245 179.701 31.8038 180.155 31.8387 179.387L32.118 78.8568C32.118 77.9845 32.921 77.2866 33.7937 77.3564L47.3742 78.5079C48.0724 78.6126 48.631 79.2407 48.5961 80.0432Z"*/}
                                {/*            fill="#D48460"/>*/}
                                {/*        <path*/}
                                {/*            d="M60.1166 127.499L54.6007 178.864C54.5309 179.701 48.945 180.155 48.945 179.387L48.7007 78.8569C48.7007 78.0194 60.1515 126.732 60.1166 127.499Z"*/}
                                {/*            fill="#D48460"/>*/}
                                {/*        <path*/}
                                {/*            d="M55.1939 178.41L55.6129 180.643C56.0667 181.516 62.63 184.447 62.63 184.447C63.1188 184.621 63.7123 185.145 63.7472 185.703C63.7821 186.436 62.9791 187.029 62.3507 187.029H49.3638C48.1768 187.029 47.2691 185.773 47.5135 184.482L48.5957 178.445H55.1939V178.41Z"*/}
                                {/*            fill="#294366"/>*/}
                                {/*        <path*/}
                                {/*            d="M37.6687 178.409C37.6687 178.409 37.0752 182.841 37.3894 184.167C37.7385 185.493 37.8781 187.063 34.6663 187.063C31.4545 187.063 27.3349 187.063 27.3349 187.063C27.3349 187.063 25.2752 185.458 27.1604 183.853L28.5568 183.678L30.9657 178.305L37.6687 178.409Z"*/}
                                {/*            fill="#294366"/>*/}
                                {/*        <path*/}
                                {/*            d="M40.8807 11.2672C40.8807 11.2672 35.5393 19.7116 31.1754 22.1891C25.8689 25.19 23.2855 29.2028 23.8091 34.088C24.3677 38.9732 14.2784 41.2413 19.2358 51.1512C23.3902 59.4211 41.8583 54.5709 41.8583 54.5709L56.905 42.3928C56.905 42.3928 65.6328 42.1834 62.0719 32.5526C60.5358 28.3653 65.8074 26.2019 63.6429 21.0027C60.9896 14.617 56.4162 10.0459 56.4162 10.0459L40.8807 11.2672Z"*/}
                                {/*            fill="#7D5034"/>*/}
                                {/*        <path*/}
                                {/*            d="M56.4512 36.3565C56.4512 36.3565 53.3092 19.9213 47.4441 21.2124C41.579 22.4686 42.9406 31.3317 35.9234 33.7394C29.2205 36.0424 24.8914 41.6255 30.1281 48.2554C33.4796 52.4776 49.2595 46.8247 49.8879 46.8247C50.5163 46.7898 56.4512 36.3565 56.4512 36.3565Z"*/}
                                {/*            fill="#40291B"/>*/}
                                {/*        <path*/}
                                {/*            d="M73.9764 65.667L72.9989 66.2602C71.2533 67.3768 69.0539 66.8883 68.1462 65.2483L56.2066 43.6139C55.2989 41.939 55.9622 39.6709 57.7077 38.5892L58.6853 37.996C60.4308 36.8794 63.119 38.6939 64.0267 40.3339L75.4775 60.6074C76.4201 62.2823 75.7568 64.5504 73.9764 65.667Z"*/}
                                {/*            fill="#294366"/>*/}
                                {/*        <path*/}
                                {/*            d="M33.5843 78.0547C33.5843 78.0547 27.3352 87.9297 29.779 97.8048C32.2228 107.68 32.2228 107.68 32.2228 107.68L45.5938 106.528L47.7234 81.6837L33.5843 78.0547Z"*/}
                                {/*            fill="#385680"/>*/}
                                {/*        <path*/}
                                {/*            d="M62.2458 137.235H32.5015C29.5689 137.235 27.2299 134.758 27.4394 131.827L29.7784 97.8397L42.1021 75.1934H62.1062L62.2458 137.235Z"*/}
                                {/*            fill="#385680"/>*/}
                                {/*        <path*/}
                                {/*            d="M50.2362 32.971C45.8374 32.6919 42.3462 28.8535 42.4859 24.422L42.8001 14.9308C42.9397 10.4992 46.6752 7.07957 51.074 7.35872C55.4728 7.63788 58.964 11.4762 58.8243 15.9078L58.5101 25.399C58.3705 29.8306 54.635 33.2502 50.2362 32.971Z"*/}
                                {/*            fill="#D48460"/>*/}
                                {/*        <path*/}
                                {/*            d="M49.678 49.511C42.6958 50.0344 32.4668 41.6249 29.7088 39.7406L41.7881 35.3091C43.2194 33.5295 43.6384 35.344 43.9875 35.4487C44.3017 35.3091 45.6981 35.2742 46.7804 35.5882C49.7129 36.3908 54.1118 36.7746 57.3934 37.6819C59.7674 38.5542 59.9768 41.59 59.907 42.0785C59.4881 45.6377 54.8798 49.1272 49.678 49.511Z"*/}
                                {/*            fill="#EBB85B"/>*/}
                                {/*        <path*/}
                                {/*            d="M44.7902 38.9727H45.3837C47.234 39.0076 49.2937 38.2399 49.3286 36.8441L49.0843 26.7248C49.1192 25.364 47.618 24.2124 45.7328 24.1776H45.1393C43.289 24.1427 41.718 25.2593 41.6831 26.6201L40.8452 36.9139C40.8103 38.3446 42.9049 38.9378 44.7902 38.9727Z"*/}
                                {/*            fill="#D48460"/>*/}
                                {/*        <path*/}
                                {/*            d="M38.7163 24.1436C39.3447 25.9581 40.776 27.0049 41.8583 26.4815C42.9405 25.9232 43.3245 24.004 42.6961 22.1895C42.0677 20.375 40.6363 19.3282 39.5541 19.8516C38.4369 20.4099 38.0878 22.3291 38.7163 24.1436Z"*/}
                                {/*            fill="#D48460"/>*/}
                                {/*        <path*/}
                                {/*            d="M51.5285 10.5694C51.5285 10.5694 58.9474 13.2912 58.8078 18.2462C58.8078 18.2462 62.7699 6.69619 52.0521 6.0332L51.5285 10.5694Z"*/}
                                {/*            fill="#7D5034"/>*/}
                                {/*        <path*/}
                                {/*            d="M51.5777 10.6228C51.5777 10.6228 43.5881 15.122 43.7078 21.5915L37.783 22.0842C37.783 22.0842 34.8455 3.79969 52.0515 6.03284L51.5777 10.6228Z"*/}
                                {/*            fill="#7D5034"/>*/}
                                {/*        <path*/}
                                {/*            d="M56.7652 76.2751L40.5664 75.8215C37.0753 75.9611 29.9185 74.8793 29.9185 71.9831L29.744 46.6151C29.709 43.7189 32.5368 41.2763 36.0629 41.1367L56.5209 40.4039C60.012 40.2643 59.4534 42.8116 59.4883 45.7078L62.0019 71.0061C62.0368 73.9372 60.2913 76.1355 56.7652 76.2751Z"*/}
                                {/*            fill="#EBB85B"/>*/}
                                {/*        <path*/}
                                {/*            d="M60.1162 86.9527C61.757 85.9756 62.6996 84.0913 62.5251 82.1024L61.1286 65.5974C66.3304 59.1071 64.8292 53.3147 61.4428 44.6261L61.373 41.4507C61.5127 39.5664 60.3257 37.8915 58.5801 37.5076L54.8795 36.7051L53.134 89.0114L60.1162 86.9527Z"*/}
                                {/*            fill="#294366"/>*/}
                                {/*        <path*/}
                                {/*            d="M48.9802 35.2744C48.7707 36.7748 48.247 37.8914 46.9902 38.5195L49.5737 42.8464C51.424 41.7298 52.0873 39.7059 51.9476 36.3212C51.9127 35.6582 51.4589 35.065 50.7956 34.8905L49.9228 34.6463C49.4689 34.5416 49.0151 34.8556 48.9802 35.2744Z"*/}
                                {/*            fill="white"/>*/}
                                {/*        <path*/}
                                {/*            d="M45.1392 43.3694L47.4783 38.6936C44.2315 37.7863 42.486 37.1233 41.6132 35.4833C41.1593 34.6458 40.1818 34.262 39.309 34.6458C38.3315 35.0646 37.8777 36.2161 38.4014 37.1931C39.4836 39.2519 41.8226 41.1711 45.1392 43.3694Z"*/}
                                {/*            fill="white"/>*/}
                                {/*        <path*/}
                                {/*            d="M32.8161 77.9144C34.8061 79.38 32.2575 70.6565 34.1078 67.83L33.7238 50.5225C35.5741 47.731 35.1202 43.6484 33.27 41.0662L32.9208 40.473C30.9309 39.0075 26.8114 40.2287 24.9611 43.0203L20.6321 72.6105C20.6321 83.2532 32.8161 77.9144 32.8161 77.9144Z"*/}
                                {/*            fill="#294366"/>*/}
                                {/*        <path*/}
                                {/*            d="M53.8671 35.9018C53.8671 35.9018 52.0866 34.8899 52.2263 36.5997C54.5653 44.73 54.7399 54.7447 53.7624 65.7712L59.3831 54.7796L56.9044 48.9871L60.0115 37.7163L53.8671 35.9018Z"*/}
                                {/*            fill="#385680"/>*/}
                                {/*        <path*/}
                                {/*            d="M38.4014 35.9023C38.4014 35.9023 43.5682 50.8719 43.7079 61.5496C43.8475 72.2272 43.7777 88.7321 43.7777 88.7321C43.7777 88.7321 30.2321 88.2087 29.2197 82.9048C28.2422 77.6358 29.7434 39.7407 29.7434 39.7407L38.4014 35.9023Z"*/}
                                {/*            fill="#294366"/>*/}
                                {/*        <path*/}
                                {/*            d="M53.6925 65.9114L49.1192 67.1327C47.5831 67.4468 47.6529 68.6332 47.9671 70.3779C48.2813 72.1226 48.4558 72.9949 49.9919 72.6809L54.8795 72.2621C56.4156 71.9481 57.428 70.2732 57.0789 68.5285C56.7647 66.7489 55.2286 65.5974 53.6925 65.9114Z"*/}
                                {/*            fill="#D48460"/>*/}
                                {/*        <path*/}
                                {/*            d="M47.0244 67.1327L29.1149 68.3889C27.4392 68.5285 24.8208 69.7847 24.9256 71.669C24.9256 71.669 24.0179 77.3218 29.9179 76.8682L47.8273 72.9251C49.5031 72.7856 50.2711 71.7387 50.1664 69.8545C50.0965 67.9353 48.7001 66.9582 47.0244 67.1327Z"*/}
                                {/*            fill="#D48460"/>*/}
                                {/*        <path*/}
                                {/*            d="M37.1799 36.007C37.1799 36.007 38.7858 35.0649 39.0302 36.7747C41.7184 45.2191 43.9527 54.3264 43.7432 66.679L36.8308 54.9545L39.2397 48.534L32.5018 38.4147L37.1799 36.007Z"*/}
                                {/*            fill="#385680"/>*/}
                                {/*        <path*/}
                                {/*            d="M56.0667 79.5197H69.3679C72.4052 79.5197 75.6868 77.0771 76.6294 74.0762L84.1004 50.5924C85.043 47.5915 83.3673 45.1489 80.2951 45.1489H66.9939C63.9567 45.1489 60.675 47.5915 59.7324 50.5924L52.2614 74.0762C51.3188 77.0771 53.0294 79.5197 56.0667 79.5197Z"*/}
                                {/*            fill="#DDDDDD"/>*/}
                                {/*        <path*/}
                                {/*            d="M83.1927 59.8045L82.669 58.7926C81.7962 57.1526 79.527 58.0947 78.6542 60.5722C78.6193 60.6769 78.5844 60.7816 78.5495 60.8863C77.9211 62.9101 78.4796 64.7595 79.8063 65.0038L81.2376 65.1084C82.5991 64.8293 84.4844 60.0488 83.1927 59.8045Z"*/}
                                {/*            fill="#D48460"/>*/}
                                {/*    </g>*/}
                                {/*    <defs>*/}
                                {/*        <clipPath id="clip0_8959_13895">*/}
                                {/*            <rect width="213.297" height="187" fill="white"/>*/}
                                {/*        </clipPath>*/}
                                {/*    </defs>*/}
                                {/*</svg>*/}
                            </div>

                            <h2 className="modal-title h4">Package feed</h2>
                            <p>Download your As-Built Survey package anytime and view files by clicking on any
                                folder. Pros and vendors may see the package and reach out if they're interested in
                                your project. You can also share the link with anyone you choose.</p>

                            {/*<h2 className="modal-title h4">Free leads feed</h2>*/}
                            {/*<p>Explore project leads from Property Owners on Fazzad.com. Click the 'Contact Property*/}
                            {/*    Owners' button to reach out and gather more information about the opportunities that*/}
                            {/*    interest you.</p>*/}
                        </div>
                        <div className="modal__action">
                            <div className="button-group">
                                <button className="button button-m button-primary modal-close" type="button"
                                        onClick={() => setShowModal(false)}>Close
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            }
        </>
    )
};
